<!--Main Navigation-->
<header id="header">
  <!--Navbar-->
  <nav class="navbar navbar-expand-lg navbar-dark primary-color fixed-top malcom-navbar">
    <div class="container">
      <!-- Navbar brand -->
      <a class="navbar-brand text-monospace" href="#"><span class="green-text font-weight-bold">Malcom</span> IO</a>
    </div>
  </nav>
  <!--/.Navbar-->

  <!--Mask-->
  <div id="intro" class="view">
    <div class="container-fluid d-flex align-items-center justify-content-center h-100">
      <div class="row d-flex justify-content-center text-center align-items-center">
        <div class="col-md-10 text-white">
          <!-- Heading -->
          <h1 class="cover-heading">Building better</h1>
          <!-- <h2 class="display-4 font-weight-bold pt-5 mb-2">Building better.</h2> -->

          <!-- Divider -->
          <hr class="hr-light" />

          <!-- Description -->
          <!-- <h4 class="white-text my-4">
                      Building custom solutions and treating each of our customers with an individual
                      experience. Primary focus is on software; however, we love to build anything
                      that is interesting and has a focus on improving the world.
                  </h4> -->
          <p class="lead">
            Welcome to <span class="green-text">Malcom</span> IO. We are focused on building custom solutions and
            treating each of our
            customers with an individual experience. Primary focus is on software; however, we love to build
            anything that is interesting and has a focus on improving the world.
          </p>
          <!-- <button type="button" class="btn btn-outline-white">
                    Read more<i class="fa fa-book ml-2"></i>
                  </button> -->
          <p class="lead"><a href="home#best-features" class="btn btn-lg btn-secondary">Learn more</a></p>
        </div>
      </div>
    </div>
  </div>
  <div  class="mask">

  </div>
  <!--/.Mask-->
</header>
<!--Main Navigation-->

<!--Main layout-->
<main class="mt-5">
  <div class="smooth container">
    <!--Section: Best Features-->
    <section id="best-features" class="text-center">
      <!-- Heading -->
      <h2 class="mb-5 font-weight-bold">Building Better</h2>

      <!--Grid row-->
      <div class="row d-flex justify-content-center mb-4">
        <!--Grid column-->
        <div class="col-md-8">
          <!-- Description -->
          <p class="grey-text">
            Passion and love for building solutions that work.
          </p>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->

      <!--Grid row-->
      <div class="row">
        <!--Grid column-->
        <div class="col-md-4 mb-5">
          <i class="fa fa-medkit fa-4x blue-text"></i>
          <h4 class="my-4 font-weight-bold">Medical Software</h4>
          <p class="grey-text">
            Whether you are looking for health interoperability or have your own custom
            application geared towards improving lives of patients we have you covered.
          </p>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-4 mb-1">
          <i class="fa fa-heart fa-4x red-text"></i>
          <h4 class="my-4 font-weight-bold">Happiness</h4>
          <p class="grey-text">
            Easy to work with and ensuring we listen to your needs and come in on budget and on
            schedule.
          </p>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-4 mb-1">
          <i class="fas fa-hammer fa-4x green-text"></i>
          <h4 class="my-4 font-weight-bold">Adventure</h4>
          <p class="grey-text">
            We are happy to work on any of your projects. We love building things that will
            improve the lives of every day people. If you have a project idea or concept let us
            know.
          </p>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </section>
    <!--Section: Best Features-->

    <!--Contact-->
    <app-contact></app-contact>
    <!-- <section id="best-features" class="text-center">
      <form action="https://formspree.io/mjvoeyzw" method="POST">
        <input type="text" name="name">
        <input type="email" name="_replyto">
        <input type="submit" value="Send">
        <re-captcha (resolved)="resolved($event)" siteKey="6LcZleIUAAAAAMRW1yyDXvUHza9EBAesB0fKYMVz"></re-captcha>
      </form>
    </section> -->
    <!--Contact-->
  </div>
</main>
<!--Main layout-->

<hr class="my-5" />

<!-- Footer -->
<footer class="page-footer font-small unique-color-dark">
  <div class="footer-copyright text-center py-3 text-monospace">
    © Copyright: <a href="https://malcom.io"> Malcom IO</a> {{ currentDate | date: 'yyyy' }}
  </div>
</footer>
<!-- Footer -->
